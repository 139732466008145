import React from "react";
import logo from "../../assets/logo.png";
import "./App.css";
import DeclaForm from "../DeclaForm/DeclaForm";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { LoginPage } from "../Login/LoginPage";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignOutButton } from "../Login/SignOutButton";

const MainContent = () => {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <DeclaForm />
          </header>
        </div>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <h5 className="card-title">Log in om de declaratie-app te gebruiken</h5>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className="App">
      {isAuthenticated && (
        <div className="logout-button-container">
          <SignOutButton />
        </div>
      )}
      {isAuthenticated ? <MainContent /> : <LoginPage />}
    </div>
  );
}

import React, { useState, useRef, useEffect as scrollToPost } from 'react'
import './DeclaForm.css'
import axios from 'axios'
import jspdf from 'jspdf'
import 'jspdf-autotable'
import { categoryOptions } from '../../data/categories'
import FileInputField from '../InputField/FileInputField'
import SelectedFiles from '../InputField/SelectedFiles'
import InputField from '../InputField/InputField'
import SelectField from '../InputField/SelectField'
import useGraphData from '../../hooks/graph'

function DeclaForm() {
    const { userName } = useGraphData()
    const [name, setName] = useState('')
    const [bankAccountNumber, setBankAccountNumber] = useState('')
    const [posts, setPosts] = useState([
        {
            description: '',
            category: '',
            totalAmountWithoutTaxes: '',
            taxAmount: '',
        },
    ])
    const fileInput = useRef()
    const [selectedFiles, setSelectedFiles] = useState([])
    const categorySelectRefs = useRef([
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
    ])
    const newPostRef = useRef(null)

    const dateToday = new Date().toLocaleDateString('nl-NL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    })

    scrollToPost(() => {
        if (userName) {
            setName(userName)
        }
    }, [userName])

    const handlePostChange = (index, field, value) => {
        const newPosts = [...posts]
        newPosts[index][field] = value
        setPosts(newPosts)
    }

    const generatePDF = (totalAmount) => {
        const doc = new jspdf({
            orientation: 'landscape',
            unit: 'mm',
            format: 'a4',
        })

        doc.setFontSize(10)
        doc.text(`Naam declarant: ${name}`, 15, 10)
        doc.text(`Bankrekeningnummer: ${bankAccountNumber}`, 15, 15)
        doc.text(`Declaratienummer: ${name}-${dateToday}`, 15, 20)
        doc.text(`Declaratie omschrijving: ${name}-${dateToday}`, 15, 25)
        doc.text(
            `Totaalbedrag van deze declaratie: € ${totalAmount.toLocaleString('nl-NL')}`,
            15,
            30
        )

        const headers = [
            [
                'Datum',
                'Omschrijving',
                'Categoriecode',
                'Categorienaam',
                'Bedrag (excl. BTW)',
                'BTW bedrag',
                'Totaalbedrag',
            ],
        ]
        const data = posts.map((post, index) => {
            const categoryName =
                categorySelectRefs.current[index].current.options[
                    categorySelectRefs.current[index].current.selectedIndex
                ].text
            const totalAmount =
                Number(post.totalAmountWithoutTaxes) + Number(post.taxAmount)
            return [
                dateToday,
                post.description,
                post.category,
                categoryName.substring(0, 20),
                '€ ' +
                    Number(post.totalAmountWithoutTaxes).toLocaleString(
                        'nl-NL'
                    ),
                '€ ' + Number(post.taxAmount).toLocaleString('nl-NL'),
                '€ ' + totalAmount.toLocaleString('nl-NL'),
            ]
        })

        doc.autoTable({
            head: headers,
            body: data,
            startY: 35,
            styles: { cellWidth: 'wrap', cellPadding: 1, fontSize: 8 },
            tableWidth: 'auto',
            setFontSize: 10,
        })

        const pdfData = doc.output('blob')
        return pdfData
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let newTab = null

        if (selectedFiles.length === 0) {
            alert('Upload alstublieft minstens één bestand.')
            fileInput.current.focus()
            return
        }

        for (let i = 0; i < posts.length; i++) {
            const post = posts[i]
            if (post.category !== '4505' && post.taxAmount === '') {
                if (
                    !window.confirm(
                        `Het BTW bedrag van post ${i + 1} is niet ingevuld. Wilt u toch doorgaan?`
                    )
                ) {
                    return
                }
            }
        }

        const totalAmount = posts.reduce(
            (acc, post) =>
                acc +
                Number(post.totalAmountWithoutTaxes) +
                Number(post.taxAmount),
            0
        )

        const form = new FormData()
        form.append('from', 'Decla App <declaapp@newspark.nl>')
        form.append('to', `newspark <rp@newspark.nl>`)
        form.append('subject', `Declaratie van ${name} - ${dateToday}`)
        form.append(
            'text',
            'Beste Rowald,\n\n' +
                'Hier weer een declaratie gemaakt met de decla-app, opgesteld door ' +
                name +
                ' van Newspark.\n\n' +
                'Het totaalbedrag van deze decla is: € ' +
                totalAmount.toLocaleString('nl-NL') +
                '.\nDit kan worden uitgekeerd naar rekeningnr: ' +
                bankAccountNumber +
                '\n\nVragen? neem contact op met ' +
                name +
                ' of met de bouwers van de decla-app.\n\nMvg,\n\nDecla-app'
        )

        Array.from(fileInput.current.files).forEach((file) => {
            form.append('attachment', file)
        })

        const wantsCopy = window.confirm('Wilt u een kopie van de PDF openen?')

        if (wantsCopy) {
            newTab = window.open('', '_blank')
        }

        const pdfData = generatePDF(totalAmount)
        const fileName = `Declaratie van ${name} - ${dateToday}.pdf`
        form.append('attachment', pdfData, fileName)

        axios
            .post(
                'https://api.mailgun.net/v3/sandbox1d23fd0adedd448b8a08a0223004401a.mailgun.org/messages',
                form,
                {
                    auth: {
                        username: 'api',
                        password:
                            '885d08aa32450a327ecd0321670756ac-4b670513-d97c74e8',
                    },
                }
            )
            .then((response) => {
                console.log('Email sent successfully')
                alert('Declaratie succesvol verzonden')

                if (wantsCopy && newTab) {
                    const url = URL.createObjectURL(pdfData)
                    newTab.location.href = url
                }

                setPosts([
                    {
                        description: '',
                        category: '',
                        totalAmountWithoutTaxes: '',
                        taxAmount: '',
                    },
                ])
                setSelectedFiles([])
            })
            .catch((error) => {
                console.error('There was an error sending the email', error)
                alert(error.message)
            })
    }

    const handleFileChange = (e) => {
        setSelectedFiles(Array.from(e.target.files))
    }

    const addPost = () => {
        setPosts((prevPosts) => {
            const newPosts = [
                ...prevPosts,
                {
                    id: prevPosts.length + 1,
                    totalAmountWithoutTaxes: '',
                    taxAmount: '',
                },
            ]
            return newPosts
        })
    }

    scrollToPost(() => {
        if (newPostRef.current) {
            newPostRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [posts])

    const removePost = () => {
        setPosts(posts.slice(0, -1))
    }

    return (
        <div>
            <h2>Declaratie Formulier</h2>
            <form onSubmit={handleSubmit}>
                <InputField
                    label="Naam declarant"
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <InputField
                    label="Bankrekeningnummer"
                    type="text"
                    id="bankAccountNumber"
                    value={bankAccountNumber}
                    onChange={(e) => setBankAccountNumber(e.target.value)}
                    required
                />

                <FileInputField
                    id="fileInput"
                    fileInput={fileInput}
                    handleFileChange={handleFileChange}
                />
                <SelectedFiles selectedFiles={selectedFiles} />

                {posts.map((post, index) => (
                    <div
                        key={post.id}
                        ref={index === posts.length - 1 ? newPostRef : null}
                    >
                        <h3>Post {index + 1}</h3>
                        <InputField
                            label="Omschrijving"
                            type="text"
                            id={`description${index}`}
                            value={post.description}
                            onChange={(e) =>
                                handlePostChange(
                                    index,
                                    'description',
                                    e.target.value
                                )
                            }
                            required
                            maxLength={50}
                        />
                        <SelectField
                            label="Categorie"
                            id={`category${index}`}
                            value={post.category}
                            onChange={(e) =>
                                handlePostChange(
                                    index,
                                    'category',
                                    e.target.value
                                )
                            }
                            required
                            options={categoryOptions}
                            ref={categorySelectRefs.current[index]}
                        />
                        <InputField
                            label="Bedrag (excl. BTW)"
                            type="text"
                            id={`totalAmountWithoutTaxes${index}`}
                            value={post.totalAmountWithoutTaxes
                                .toString()
                                .replace('.', ',')}
                            onChange={(e) => {
                                const formattedValue = e.target.value.replace(
                                    ',',
                                    '.'
                                )
                                handlePostChange(
                                    index,
                                    'totalAmountWithoutTaxes',
                                    formattedValue
                                )
                            }}
                            required
                        />
                        <InputField
                            label="BTW bedrag"
                            type="text"
                            id={`taxAmount${index}`}
                            value={post.taxAmount.toString().replace('.', ',')}
                            onChange={(e) => {
                                const formattedValue = e.target.value.replace(
                                    ',',
                                    '.'
                                )
                                handlePostChange(
                                    index,
                                    'taxAmount',
                                    formattedValue
                                )
                            }}
                        />
                    </div>
                ))}

                {posts.length > 1 && (
                    <button type="button" onClick={removePost}>
                        Haal laatste post weg
                    </button>
                )}
                {posts.length < 9 && (
                    <button type="button" onClick={addPost}>
                        Voeg extra post toe
                    </button>
                )}

                <button type="submit">Opsturen</button>
            </form>
        </div>
    )
}

export default DeclaForm

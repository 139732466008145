export const categoryOptions = [
  {
    value: "4201",
    label: "Brandstofkosten (Tanken, laadkosten thuis & onderweg)",
  },
  {
    value: "4505",
    label: "Lunch & Diners (Maaltijdkosten met klanten / collega's), geen BTW invoeren!",
  },
  { value: "4206", label: "Parkeerkosten (Voor zakelijk parkeren)" },
  {
    value: "4110",
    label: "Studiekosten (Opleiding gerelateerde kosten)",
  },
  {
    value: "4259",
    label: "Contributie & Abonnementen (Testnet, ChatGPT, Meetup.org)",
  },
  {
    value: "4205",
    label: "Onderhoud en reparatie auto's (Alles aan je leaseauto)",
  },
  {
    value: "4108",
    label:
      "Overige Personeelskosten (Bloemetje voor klanten, VOG, kosten screening)",
  },
  {
    value: "4502",
    label: "Reis - & Verblijfkosten (KM's. OV en hotelovernachtingen)",
  },
  {
    value: "4260",
    label:
      "Klein inventaris (thuiswerkplek) (Bureaustoel, bureau, docking station, monitor, headset)",
  },
  {
    value: "4252",
    label: "Kantoorkosten (Papier etc. voor op kantoor)",
  },
  {
    value: "4253",
    label:
      "Telefoonkosten (Als het goed is niet nodig daar je al een vergoeding krijgt)",
  },
  { value: "4407", label: "Algemene Kosten (Alle overige kosten)" },
];

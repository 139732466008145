import React from "react";
import logo from "../../assets/logo.png";

import { SignInButton } from "./SignInButton";

import "./LoginPage.css";

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props
 */
export const LoginPage = (props) => {
  return (
    <div className="login-page">
      <img src={logo} className="App-logo" alt="logo" />
      <h2>
        <center>Welkom bij de decla-app!</center>
      </h2>
      <SignInButton />
      <br />
      <br />
      {props.children}
    </div>
  );
};

import React from "react";

const SelectField = React.forwardRef(
  ({ label, id, value, onChange, required, options }, ref) => (
    <div className="input-group">
      <label htmlFor={id}>
        {label} <span style={{ color: "red" }}>*</span>
      </label>
      <select
        required={required}
        id={id}
        value={value}
        onChange={onChange}
        ref={ref}
      >
        <option value="">Selecteer een {label.toLowerCase()}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  ),
);

export default SelectField;

import React from "react";

const SelectedFiles = ({ selectedFiles }) => (
  <div className="selected-files">
    <label>Geselecteerde bestanden:</label>
    <ul>
      {selectedFiles.map((file, index) => (
        <li key={index}>{file.name}</li>
      ))}
    </ul>
  </div>
);

export default SelectedFiles;

import React, { useState } from 'react'

const InputField = ({
    label,
    type,
    id,
    value,
    onChange,
    required,
    maxLength,
}) => {
    const [error, setError] = useState('')

    const handleChange = (e) => {
        if (e.target.value.length > maxLength) {
            setError(`Maximum length of ${maxLength} characters reached`)
        } else {
            setError('')
        }
        onChange(e)
    }

    return (
        <div className="input-group">
            <label htmlFor={id}>
                {label} {required && <span style={{ color: 'red' }}>*</span>}
            </label>
            <input
                required={required}
                type={type}
                id={id}
                value={value}
                onChange={handleChange}
                maxLength={maxLength}
            />
            {error && <span style={{ color: 'red' }}>{error}</span>}
        </div>
    )
}

export default InputField
